import React from 'react'

import { Settings } from './Constants'
import { HierarchyItem } from './models/Hierarchy'
import { AuthenticationResult } from './Authentication'

export function findById(this: Hierarchies, hierarchy: keyof Hierarchies, id: string): HierarchyItem {
    let item = this[hierarchy]!.find(hi => hi.id == id)

    return item!
}

interface Hierarchies {
    formTypes: Array<HierarchyItem>
    applicationTypes: Array<HierarchyItem>
    intakes: Array<HierarchyItem> 
    bmps: Array<HierarchyItem> 
}

export interface AppContextType extends Hierarchies {
    settings: Settings
    setFluidWidth(fullScreen?: boolean): void
    findById(hierarchy: keyof Hierarchies, id: string): HierarchyItem
    login: (authenticationResult: AuthenticationResult) => void
    logout: () => void
}

export const AppContext = React.createContext<AppContextType>({
    settings: { environment: '', dataExchangeDB: '', dirtDB: '', capDB: '', version: '' },
    formTypes:[],
    applicationTypes: [],
    intakes: [],
    bmps: [],
    findById, 
    setFluidWidth: () => null,
    login: () => null,
    logout: () => null,
})

export default AppContext
